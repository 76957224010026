import React from 'react';
import { graphql } from 'gatsby';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import CovidArticleArea02 from '../components/CovidArticleArea02';

const CovidArticleList02 = ({ data: { allMarkdownRemark } }) => {
  const covid19Prevention = allMarkdownRemark.edges
  .filter(edge => !!edge.node.frontmatter.date)
  .map(({ node: { id, excerpt, frontmatter: { date, title, path } } }) => ({
    id,
    excerpt,
    date,
    title,
    path,
  }));
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="新型コロナの予防">
      <div
        css={`margin: 20px;`}
        >
        <CovidArticleArea02 data={covid19Prevention} size={size} />
      </div>
    </SubLayout>
  );
};
export default CovidArticleList02;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/covid19-prevention/"} }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
  `;